<template>
  <el-select
    v-model="computedValue"
    :popper-class="popperClass"
    :placeholder="$t(placeholder)"
    v-bind="$attrs"
    filterable
    @change="(v) => $emit('change',v)"
  >
    <el-option
      v-for="(item, id) in timezones"
      :key="id"
      :label="item"
      :data-test-key="id"
      :value="id"
    />
  </el-select>
</template>

<script>
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'
import timezones from '@/constants/timezones'

export default {
  extends: BasicSelect,

  props: {
    popperClass: {
      type: String,
      default: 'timezoneModal'
    },
    placeholder: {
      type: String,
      default: 'forms.choose_time_zone'
    }
  },

  data () {
    return {
      timezones
    }
  }
}
</script>
